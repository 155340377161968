/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
import LayoutApp from "../../layout";
import { CirclePlus, Filter } from "lucide-react";
import { Box } from "@mui/material";
import XCardPost from "../../components/x-card-post";
import NewPost from "../new-post";
import { IAddressResponse, categoryIndex, useAllContext } from "../../context";
import Filters from "../filters";
import { useNavigate } from "react-router-dom";
import XEmpty from "../../components/x-empty";
import React, { useState } from "react";
import XButton from "../../components/x-button";
import { useToast } from "@chakra-ui/react";
import { create } from "../../api";
import XDialogRemoveItem from "../../components/x-dialog-remove-item";
import useGeolocation from "../../services/geolocation";
import XTagButton from "../../components/x-tag-button";

export interface IPost {
  id?: string;
  userId: string;
  type: string;
  status: string;
  createdAt: {
    date: string;
    hour: string;
  };
  text: string;
  reactions: {
    comments: number;
    prioritizations: number;
    userIdPrioritizations?: string[];
  };
  address: IAddressResponse;
  userAgent: string;
}

export default function Home() {
  const {
    handleSubmitNewPost,
    onClose,
    modal,
    onOpen,
    posts,
    user,
    handleRemove,
    onOpenRemoveDialog,
    itemId,
    fetchPost,
    loading,
    prioritizePost,
    fetchComments,
    filters,
  } = useAllContext();

  const toast = useToast();
  const nav = useNavigate();
  const { requestLocationPermission } = useGeolocation();
  return (
    <LayoutApp
      controllModal={{
        onClose,
        title: modal.post
          ? "Novo Chamado"
          : modal.alert
          ? "Confirmar"
          : modal.comment
          ? "Novo Comentário"
          : "Filtros",
        children: modal.post ? (
          <NewPost
            onSubmit={async (_JSON) => {
              const city = _JSON?.address?.city;
              if (!city) {
                toast({
                  status: "error",
                  description: "Conceda as permissões de localização",
                  isClosable: true,
                });
                const address = await requestLocationPermission();
                if (!address?.city) {
                  toast({
                    status: "error",
                    description: "Não foi possível concluir, tente novamente !",
                    isClosable: true,
                  });
                  return;
                }
                _JSON.address = address as IAddressResponse;
              }

              handleSubmitNewPost(_JSON);
              onClose();
            }}
          />
        ) : modal.alert ? (
          <XDialogRemoveItem
            title="chamado"
            onClick={async () => {
              if (
                await handleRemove({
                  _collection: "posts",
                  _docId: itemId,
                  _data: posts?.find((_p) => _p?.id === itemId),
                })
              ) {
                onClose();
                fetchPost();
                return toast({
                  description: "Chamado excluido com sucesso.",
                  status: "success",
                  isClosable: true,
                });
              }
            }}
            loading={loading}
            onClose={onClose}
          />
        ) : (
          <Filters />
        ),
        state: modal.post || modal.filter || modal.alert,
      }}
    >
      <div className="flex flex-col gap-4 overflow-hidden bg-slate-50">
        <div className="flex flex-col w-full gap-4 pt-3 pb-3 shadow  bg-white py-6 p-4">
          <div className="flex flex-row items-center justify-between w-full ">
            <span className="text-lg font-bold">
              Olá, {user?.name || "tudo bem ?"} 👋
            </span>
            <div className="flex flex-row items-center gap-3">
              <button
                onClick={() => onOpen("filter")}
                className="flex flex-row items-center justify-center gap-2  bg-gray-100 active:bg-white p-2 px-4 rounded-full"
              >
                <span className="">Filtros</span>
                <Filter />
              </button>
            </div>
          </div>
          <XButton
            onClick={() => {
              if (user?.id) return onOpen("post");
              nav("/auth");
            }}
            name="Nova Publicação"
          />
        </div>
        {filters?.category.length > 0 && (
          <div className="p-2 bg-white shadow ">
            <div className="flex flex-col w-full">
              <span className="text-base">Filtros/Categorias</span>
              <div className="gap-2 flex flex-row items-center overflow-x-scroll h-[50px] w-full ">
                {filters?.category?.map((_category, _index) => (
                  <XTagButton key={_index}>
                    <span className="text-sm whitespace-nowrap mr-2">
                      {categoryIndex[_category]}
                    </span>
                  </XTagButton>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col pb-5 gap-4 overflow-y-scroll">
          {posts?.map((i, key) => {
            const wasPrioritizedByIdUser =
              i?.reactions?.userIdPrioritizations?.includes(user?.id);
            return (
              <XCardPost
                {...i}
                variant="outlined"
                wasPrioritized={wasPrioritizedByIdUser}
                key={key}
                canRemove={i?.userId === user?.id}
                onRemove={() => onOpenRemoveDialog(i?.id || "")}
                onClick={() => {
                  fetchComments(i?.id || "");
                  nav(`/detail-post/${i?.id}`, { state: i });
                }}
                handleComment={() => {
                  if (!user?.id) return nav("/auth");
                  fetchComments(i?.id || "");
                  onOpen("comment");
                  nav(`/detail-post/${i?.id}`, { state: i });
                }}
                handlePrioritize={() => {
                  if (!user?.id) return nav("/auth");
                  prioritizePost({
                    _idPost: i?.id || "",
                    _wasPrioritizedByIdUser: wasPrioritizedByIdUser as boolean,
                  });
                }}
              />
            );
          })}
          {!posts?.length && (
            <>
              <div className="h-20" />
              <XEmpty />
            </>
          )}
        </div>
      </div>
    </LayoutApp>
  );
}
