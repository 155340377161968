import React from "react";
import XButton from "../x-button";
interface IDialogRemoveItem {
  loading: boolean;
  title: string;
  onClick: () => void;
  onClose: () => void;
}
export default function XDialogRemoveItem({
  title,
  loading,
  onClick,
  onClose,
}: IDialogRemoveItem) {
  return (
    <div className="flex flex-col gap-6">
      <span>Você concorda em excluir esse {title} ?</span>
      <div className="flex flex-row items-center justify-center gap-2">
        <XButton onClick={onClose} variant="outlined" name="Não" />
        <XButton loading={loading} onClick={onClick} name="Sim" />
      </div>
    </div>
  );
}
