import XButton from "../x-button";

export default function XDialogItemResolved(props: {
  title: string;
  subtitle: string;
  onClick: () => void;
  loading: boolean;
  variant?: "text" | "outlined" | "contained";
  labelButton?: string;
}) {
  return (
    <div className="flex flex-col w-full bg-white shadow">
      <div className="flex flex-col p-5">
        <span className="text-lg font-bold">{props?.title}</span>
        <span className="text-sm text-gray-600">{props?.subtitle}</span>
      </div>
      <div className="flex flex-row items-center justify-center gap-2 p-2">
        <XButton
          variant={props?.variant}
          loading={props?.loading}
          onClick={props?.onClick}
          name={props?.labelButton || "Sim"}
        />
      </div>
    </div>
  );
}
