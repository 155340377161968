import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import XButton from "../../../components/x-button";

export interface IDataLogin {
  email: string;
  password: string;
}

export default function Login(props: any) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    props?.onSubmit({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
      }}
    >
      <img
        className="h-28"
        src={require("../../../assets/logo.png")}
        alt="logo"
      />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <div className="h-4" />
        <XButton loading={props?.loading} name="Entrar" />
        <div className="h-2" />
      </Box>
    </Box>
  );
}
