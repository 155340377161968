import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import {
  QuerySnapshot,
  WhereFilterOp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "./config";

interface ICreate {
  _collection: string;
  _data: any;
}
interface IUpdate {
  _collection: string;
  _docId: string;
  _data: any;
}

interface IAuthProps {
  _email: string;
  _password: string;
}

interface IFindDocs {
  _collection: string;
  _condition?: { field: string; operator: any; value: any };
}

export const register = async (props: IAuthProps) => {
  const { _email, _password } = props;
  try {
    const res = await createUserWithEmailAndPassword(auth, _email, _password);
    const userId = res?.user?.uid || "";
    return {
      msg: "s",
      data: userId,
    };
  } catch (error: any) {
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const login = async (props: IAuthProps) => {
  const { _email, _password } = props;
  try {
    const res = await signInWithEmailAndPassword(auth, _email, _password);
    return {
      msg: "s",
      data: res?.user?.uid || "",
    };
  } catch (error: any) {
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const handleSignOut = async () => {
  return await auth.signOut();
};

export const create = async (props: ICreate) => {
  const { _collection, _data } = props;
  try {
    const ref = collection(db, _collection);
    const res = await addDoc(ref, _data);
    return {
      msg: "s",
      data: res?.id,
    };
  } catch (error: any) {
    console.error("Error creating document:", error.message);
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const update = async (props: IUpdate) => {
  const { _collection, _docId, _data } = props;
  try {
    await updateDoc(doc(db, _collection, _docId), _data);
    return { msg: "s", data: "Documento atualizado com sucesso" };
  } catch (error: any) {
    console.error("Erro ao atualizar documento:", error.message);
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const readAll = async (props: { _collection: string }) => {
  try {
    const q = query(collection(db, props?._collection));
    const querySnapshot: QuerySnapshot = await getDocs(q);

    const documentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      msg: "s",
      data: documentsData,
    };
  } catch (error: any) {
    console.error("Error fetching documents:", error.message);
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const findDocs = async (props: IFindDocs) => {
  try {
    let q = query(collection(db, props._collection));
    if (props?._condition) {
      q = query(
        q,
        where(
          props?._condition.field,
          props?._condition.operator,
          props?._condition.value
        )
      );
    }
    const querySnapshot: QuerySnapshot = await getDocs(q);
    const documentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return {
      msg: "s",
      data: documentsData,
    };
  } catch (error: any) {
    console.error("Error fetching documents:", error.message);
    return {
      msg: "e",
      data: error?.code,
    };
  }
};

export const remove = async (props: {
  _collection: string;
  _docId?: string;
  condition?: { field: string; operator: WhereFilterOp; value: any };
}) => {
  const { _collection, _docId, condition } = props;
  try {
    let ref;
    if (_docId) {
      ref = doc(db, `${_collection}/${_docId}`);
      await deleteDoc(ref);
      return {
        msg: "s",
        data: "Document removed successfully",
      };
    } else {
      const q = query(
        collection(db, _collection),
        where(
          condition?.field as string,
          condition?.operator as WhereFilterOp,
          condition?.value
        )
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      return {
        msg: "s",
        data: "Documents removed successfully based on condition",
      };
    }
  } catch (error: any) {
    console.error("Error removing document(s):", error);
    return {
      msg: "e",
      data: error?.code,
    };
  }
};
