import * as React from "react";
import { APP } from "../../utils/i18n";
import { Menu } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAllContext } from "../../context";
import { Login } from "@mui/icons-material";

export default function XAppBar({ hiddenButton }: { hiddenButton?: boolean }) {
  const nav = useNavigate();
  const { user } = useAllContext();

  return (
    <div className="w-full h-[60px] items-center justify-between flex bg-red-500 text-lg text-white font-bold p-4">
      <span>{APP}</span>
      {!hiddenButton && (
        <button
          onClick={() =>
            user?.id ? nav(`/profile/${user?.id}`) : nav("/auth")
          }
          className="flex items-center justify-center gap-6 active:bg-red-300 bg-red-400 p-2 rounded-full"
        >
          {user?.id ? <Menu /> : <Login />}
        </button>
      )}
    </div>
  );
}
