import React from "react";
import { useAllContext } from "../../context";
import { CircularProgress } from "@mui/material";
const text = "Parece que não há chamados";

export default function XEmpty() {
  const { loading } = useAllContext();
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
      {loading ? (
        <CircularProgress />
      ) : (
        <img
          className="h-48"
          alt="empty-vector"
          src={require("../../assets/empty.png")}
        />
      )}
      <span className="font-medium text-gray-400">
        {loading ? "Procurando chamados..." : text}
      </span>
    </div>
  );
}
