import { createBrowserRouter } from "react-router-dom";
import Home from "../modules/home";
import AuthScreen from "../modules/auth";
import Profile from "../modules/profile";
import DetailPost from "../modules/detail-post";
import LandingPage from "../modules/landing-page";
import KnowMore from "../modules/know-more";

export default createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/:name",
    element: <Home />,
  },
  {
    path: "/saiba-mais",
    element: <KnowMore />,
  },
  {
    path: "/auth",
    element: <AuthScreen />,
  },
  {
    path: "/profile/:id",
    element: <Profile />,
  },
  {
    path: "/detail-post/:id",
    element: <DetailPost />,
  },
]);
