import { Box, Divider, Modal, Typography } from "@mui/material";
import { X } from "lucide-react";
import { ReactNode } from "react";

export interface IModal {
  onClose?: () => void;
  state?: boolean;
  title?: string;
  children?: ReactNode;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  // height: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

export default function XModal(controllModal: IModal) {
  return (
    <Modal open={!!controllModal?.state} onClose={controllModal?.onClose}>
      <Box sx={style}>
        <div className="flex flex-row items-center justify-between">
          <span className="font-bold text-xl">{controllModal?.title}</span>
          <button
            onClick={controllModal?.onClose}
            className="bg-gray-100 rounded-full p-1"
          >
            <X size={20} />
          </button>
        </div>
        <div className="h-2" />
        <Divider />
        <div className="h-2" />
        {controllModal?.children}
      </Box>
    </Modal>
  );
}
