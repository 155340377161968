export function formatDateBorn(input: string) {
  let cleaned = input?.replace(/\D/g, "");
  if (cleaned?.length > 8) {
    cleaned = cleaned?.substr(0, 8);
  }
  let formatted = cleaned?.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  return formatted;
}

export function formatCPF(input: string) {
  let cleaned = input?.replace(/\D/g, "");
  if (cleaned?.length > 11) {
    cleaned = cleaned?.substr(0, 11);
  }
  let formatted = cleaned?.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );
  return formatted;
}

export function ageIsValid(bornDate: string) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!regex.test(bornDate)) {
    return false;
  }
  const partesData = bornDate.split("/");
  const anoNascimento = parseInt(partesData[2]);
  const anoAtual = new Date().getFullYear();
  const idade = anoAtual - anoNascimento;
  return idade < 150;
}

export function CPFIsValid(cpf: string) {
  if (!cpf) return false;
  const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
  if (!regex.test(cpf)) {
    return false; // Invalid format
  }
  const numericCPF = cpf.replace(/\D/g, "");
  if (/^(\d)\1{10}$/.test(numericCPF)) {
    return false;
  }
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(numericCPF.charAt(i)) * (10 - i);
  }
  let remainder = sum % 11;
  let verificationDigit1 = remainder < 2 ? 0 : 11 - remainder;

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(numericCPF.charAt(i)) * (11 - i);
  }
  remainder = sum % 11;
  let verificationDigit2 = remainder < 2 ? 0 : 11 - remainder;

  if (
    parseInt(numericCPF.charAt(9)) !== verificationDigit1 ||
    parseInt(numericCPF.charAt(10)) !== verificationDigit2
  ) {
    return false; // Invalid CPF
  }

  return true; // Valid CPF
}
