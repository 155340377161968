import React from "react";
import LayoutApp from "../../layout";
import { ArrowLeft, Phone } from "lucide-react";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAllContext } from "../../context";
import XButton from "../../components/x-button";
import { handleSignOut } from "../../api";

const numbers = [
  { name: "Caixa Econômica Federal", number: "111" },
  { name: "Conselhos Tutelares", number: "125" },
  { name: "Disque Direitos Humanos", number: "100" },
  { name: "Disque Denúncia", number: "181" },
  { name: "Defesa Civil", number: "199" },
  { name: "Linha da Vida - Ministério da Saúde", number: "188" },
  { name: "Marinha – Emergências Marítimas / Fluviais", number: "185" },
  { name: "Ministério da Cidadania", number: "132" },
  { name: "Ministério da Saúde - Apoio COVID-19", number: "196" },
  { name: "Ministério da Cidadania", number: "132" },
  { name: "Polícia Civil", number: "197" },
  { name: "Polícia Federal", number: "194" },
  { name: "Polícia Militar", number: "190" },
  { name: "Polícia Militar - SOMENTE CELULAR", number: "112" },
  { name: "Polícia Rodoviária Estadual", number: "198" },
  { name: "Polícia Rodoviária Federal", number: "191" },
  { name: "SAMU - Serviço de Atendimento Médico de Urgência", number: "192" },
  { name: "SABESP (Somente Estado de São Paulo)", number: "195" },
  { name: "Serviços de Emergência no Mercosul", number: "128" },
];

export default function Profile() {
  const nav = useNavigate();
  const { posts, priorizations, comments, user } = useAllContext();

  function call(number: string) {
    window.open(`tel:${number}`);
  }

  const central = () => {
    const inOpen = posts.filter(
      (_p) =>
        _p?.status?.toLowerCase().replace(" ", "") === "emaberto" &&
        _p?.userId === user?.id
    );
    const inResolved = posts.filter(
      (_p) =>
        _p?.status?.toLowerCase().replace(" ", "") === "resolvido" &&
        _p?.userId === user?.id
    );
    const inPriorizations = priorizations.filter(
      (_p) => _p?.idUser === user?.id
    );
    const inComments = comments.filter((_c) => _c?.userId === user?.id);

    return {
      inOpen,
      inResolved,
      inPriorizations,
      inComments,
    };
  };

  return (
    <LayoutApp>
      <div className="flex flex-col gap-4 pb-10 bg-slate-50">
        <div className="flex flex-row gap-2 items-center justify-start text-lg">
          <ArrowLeft onClick={() => nav(-1)} size={30} />
          <span>Informações</span>
        </div>

        <div className="rounded-sm bg-white shadow p-3 flex flex-col">
          <span className="font-bold text-lg">Resumo</span>
          <div className="h-2" />
          <Divider />
          <div className="h-2" />

          <div className="grid grid-cols-2 gap-4 ">
            <div className="flex flex-col w-full h-20 bg-gray-100 rounded-lg  justify-center items-center">
              <span>{central()?.inOpen?.length}</span>
              <span>Em Aberto</span>
            </div>
            <div className="flex flex-col w-full h-20 bg-gray-100 rounded-lg  justify-center items-center">
              <span>{central()?.inResolved?.length}</span>
              <span>Resolvidos</span>
            </div>
            <div className="flex flex-col w-full h-20 bg-gray-100 rounded-lg  justify-center items-center">
              <span>{central()?.inComments.length}</span>
              <span>Comentários</span>
            </div>
            <div className="flex flex-col w-full h-20 bg-gray-100 rounded-lg  justify-center items-center">
              <span>{central()?.inPriorizations.length}</span>
              <span>Priorizações</span>
            </div>
          </div>
        </div>

        {numbers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((entry, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-between p-2 border-solid border-2 border-gray-200 rounded-full px-4 py-2"
            >
              <span>{entry.name}</span>
              <div
                onClick={() => call(entry.number)}
                className="flex flex-row items-center justify-between gap-2"
              >
                <div className="bg-green-500 active:bg-green-300 p-2 rounded-full">
                  <Phone color="white" />
                </div>
              </div>
            </div>
          ))}

        <XButton
          name="Sair da conta"
          onClick={() => {
            handleSignOut();
            nav("/auth");
          }}
        />
      </div>
    </LayoutApp>
  );
}
