/* eslint-disable no-restricted-globals */
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IPost } from "../modules/home";
import useGeolocation from "../services/geolocation";
import { IDataLogin } from "../modules/auth/login";
import { IDataRegister } from "../modules/auth/register";
import {
  create,
  findDocs,
  login,
  readAll,
  register,
  remove,
  update,
} from "../api";
import { useToast } from "@chakra-ui/react";
import { auth } from "../api/config";
import { User, onAuthStateChanged, updateProfile } from "firebase/auth";

export type IAddressResponse = {
  road: string;
  suburb: string;
  city_district: string;
  city: string;
  municipality: string;
  state_district: string;
  state: string;
  "ISO3166-2-lvl4": string;
  region: string;
  postcode: string;
  country: string;
  country_code: string;
};

export interface IFilter {
  category: number[];
  city: string[];
  status: number;
  data: number;
}

export interface IShowFilter {
  city: boolean;
  category: boolean;
  status: boolean;
  data: boolean;
}

interface IModalHomeProps {
  post: boolean;
  filter: boolean;
  alert: boolean;
  comment: boolean;
}

interface IPriorization {
  createdAt: Date;
  idPost: string;
  idUser: string;
}

interface IResolvePost {
  post: IPost;
}
interface IFetchPost {
  getCache?: boolean;
  cityParam?: string;
}
interface IRemove {
  _collection: string;
  _docId: string;
  _data?: any;
}
export type IComment = IPost & {
  postId: string;
};

type IModalOpen = (page: "post" | "filter" | "alert" | "comment") => void;

interface IContext {
  handleSubmitNewPost: (post: IPost) => void;
  handleSubmitNewComment: (post: IComment) => void;
  fetchComments: (postId: string) => Promise<void>;
  address: IAddressResponse | undefined;
  filters: IFilter;
  setFilters: Dispatch<SetStateAction<IFilter>>;
  onClose: () => void;
  onOpen: IModalOpen;
  loading: boolean;
  modal: IModalHomeProps;
  posts: IPost[];
  priorizations: IPriorization[];
  comments: IComment[];
  commentsByPost: IComment[];
  user: {
    id: string;
    name: string;
  };
  fetchPost: (_props?: IFetchPost) => void;
  handleRegister: (_data: IDataRegister) => Promise<boolean>;
  handleLogin: (_data: IDataLogin) => Promise<boolean>;
  handleRemove: (props: IRemove) => Promise<boolean>;
  normalize: (txt?: string) => string;
  prioritizePost: (props: {
    _wasPrioritizedByIdUser: boolean;
    _idPost: string;
  }) => Promise<void>;
  onOpenRemoveDialog: (_id: string) => void;
  itemId: string;
  handleResolvePost: (_props: IResolvePost) => Promise<void>;
  setCommentsByPost: Dispatch<IComment[]>;
  showFilters: IShowFilter;
  setShowFilters: Dispatch<SetStateAction<IShowFilter>>;
}

export const cats = [
  "Buracos na Estrada",
  "Calçadas Danificadas",
  "Postes Caídos ou Danificados",
  "Vazamentos de Água",
  "Falhas na Iluminação Pública",
  "Lixo e Entulho",
  "Problemas de Drenagem",
  "Inundações",
  "Grafite e Pichações",
  "Áreas de Risco",
  "Sinalização Viária Danificada",
  "Problemas de Acessibilidade",
  "Poda de Árvores",
  "Estacionamento Irregular",
  "Poluição Ambiental",
  "Abandono de Veículos",
  "Infiltrações e Umidade",
  "Problemas de Segurança",
  "Proliferação de Pragas",
  "Desordem Urbana",
];
export const categoryIndex: { [key: number]: string } = {
  1: "Buracos na Estrada",
  2: "Calçadas Danificadas",
  3: "Postes Caídos ou Danificados",
  4: "Vazamentos de Água",
  5: "Falhas na Iluminação Pública",
  6: "Lixo e Entulho",
  7: "Problemas de Drenagem",
  8: "Inundações",
  9: "Grafite e Pichações",
  10: "Áreas de Risco",
  11: "Sinalização Viária Danificada",
  12: "Problemas de Acessibilidade",
  13: "Poda de Árvores",
  14: "Estacionamento Irregular",
  15: "Poluição Ambiental",
  16: "Abandono de Veículos",
  17: "Infiltrações e Umidade",
  18: "Problemas de Segurança",
  19: "Proliferação de Pragas",
  20: "Desordem Urbana",
};

const AllContext = createContext<IContext>({} as IContext);

export default function AllProvider({ children }: { children: ReactNode }) {
  const { requestLocationPermission } = useGeolocation();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<IAddressResponse | undefined>(
    {} as IAddressResponse
  );

  let city = normalize(address?.city) || normalize(location?.pathname);

  console.log({ city });
  let user = {
    id: auth?.currentUser?.uid || "",
    name: auth?.currentUser?.displayName || "",
  };

  const [itemId, setItemId] = useState("");
  const [posts, setPosts] = useState<IPost[]>([]);
  const [priorizations, setPriorizations] = useState<IPriorization[]>([]);
  const [comments, setComments] = useState<IComment[]>([]);
  const [commentsByPost, setCommentsByPost] = useState<IComment[]>([]);

  const [filters, setFilters] = useState<IFilter>({
    status: 1,
    data: 1,
    category: [],
    city: [],
  });
  const [showFilters, setShowFilters] = useState<IShowFilter>({
    status: false,
    data: false,
    category: false,
    city: false,
  });
  const [modal, setModal] = useState<IModalHomeProps>({
    post: false,
    filter: false,
    alert: false,
    comment: false,
  });

  const onOpen: IModalOpen = (page) => {
    setModal((prev) => ({
      ...prev,
      [page]: true,
    }));
  };

  const onClose = () => {
    setModal({
      alert: false,
      filter: false,
      post: false,
      comment: false,
    });
  };

  const onOpenRemoveDialog = (id: string) => {
    setItemId(id);
    onOpen("alert");
  };

  function convertToDate(date: string, hour: string): Date {
    const [dia, mes, ano] = date?.split("/");
    const [hora, minuto] = hour?.split(":");
    return new Date(
      parseInt(ano),
      parseInt(mes) - 1,
      parseInt(dia),
      parseInt(hora),
      parseInt(minuto)
    );
  }

  function normalize(txt?: string) {
    if (!txt) return "";
    return txt
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .replace(" ", "");
  }

  async function handleRemove(props: IRemove) {
    setLoading(true);
    const res = await remove({
      _collection: props?._collection,
      _docId: props?._docId,
    });
    if (props?._collection === "posts") {
      Promise.all([
        remove({
          _collection: "comments",
          condition: {
            field: "postId",
            operator: "==",
            value: props?._data?.id,
          },
        }),
        remove({
          _collection: "priorizations",
          condition: {
            field: "idPost",
            operator: "==",
            value: props?._data?.id,
          },
        }),
      ]);
    }
    setLoading(false);
    if (res?.msg === "s") return true;
    return false;
  }

  async function handleSubmitNewPost(json: IPost) {
    const { data } = await create({
      _collection: "posts",
      _data: json,
    });
    if (data) {
      fetchPost();
      toast({
        description: "Chamado publicado com sucesso.",
        status: "success",
        isClosable: true,
      });
      return;
    }
    toast({
      description: "Problema ao publicar !",
      status: "error",
      isClosable: true,
    });
  }

  async function handleSubmitNewComment(json: IComment) {
    const { data } = await create({
      _collection: "comments",
      _data: json,
    });
    if (data) {
      fetchPost();
      toast({
        description: "Comentário publicado com sucesso.",
        status: "success",
        isClosable: true,
      });
      return;
    }
    toast({
      description: "Problema ao publicar !",
      status: "error",
      isClosable: true,
    });
  }

  async function handleLogin(_data: IDataLogin) {
    let msg = "";
    if (!_data?.email || !_data.password) {
      toast({
        title: "Houve um problema ao entrar na conta",
        description: "Todos os campos são obrigatórios !",
        status: "error",
        isClosable: true,
      });
      return false;
    }
    setLoading(true);
    const response = await login({
      _email: _data?.email,
      _password: _data?.password,
    });

    if (response?.msg === "e") {
      if (response?.data === "auth/invalid-credential")
        msg = "Email ou senha inválido";
      setLoading(false);
      toast({
        title: "Houve um problema ao entrar na conta",
        description: msg,
        status: "error",
        isClosable: true,
      });
      return false;
    }
    setLoading(false);
    return true;
  }

  async function handleRegister(_data: IDataRegister) {
    let userId = "";
    let msg = "";
    if (
      !_data?.email ||
      !_data.password ||
      !_data?.name ||
      // !_data.cpf ||
      !_data.bornDate
    ) {
      toast({
        title: "Houve um problema ao criar a conta",
        description: "Todos os campos são obrigatórios !",
        status: "error",
        isClosable: true,
      });
      return false;
    }
    if (!_data?.email || !_data.password) {
      toast({
        title: "Houve um problema ao criar a conta",
        description: "Email e senha são obrigatórios !",
        status: "error",
        isClosable: true,
      });
      return false;
    }
    setLoading(true);
    const response = await register({
      _email: _data?.email,
      _password: _data?.password,
    });
    if (response?.msg === "e") {
      msg = response?.data;
      setLoading(false);
      if (response?.data === "auth/invalid-email") msg = "Email inválido !";
      if (response?.data === "auth/email-already-in-use")
        msg = "Email já cadastrado !";
      if (response?.data === "auth/weak-password")
        msg =
          "Certifique-se de que sua senha contenha pelo menos 6 caracteres.";

      toast({
        title: "Houve um problema ao criar a conta",
        description: msg,
        status: "error",
        isClosable: true,
      });
      return false;
    }
    userId = response?.data || "";
    if (userId) {
      await updateProfile(auth.currentUser as User, {
        displayName: _data?.name,
      });
    }
    const responseDB = await create({
      _collection: "user",
      _data: {
        ..._data,
        address: address,
        id: userId,
        userAgent: navigator.userAgent,
      },
    });
    if (responseDB?.msg === "e") {
      setLoading(false);
      toast({
        title: "Houve um problema ao criar a conta",
        description: msg,
        status: "error",
        isClosable: true,
      });
      return false;
    }
    setLoading(false);
    return true;
  }

  const fetchPostDB = async () => {
    const rawPosts = await readAll({
      _collection: "posts",
    });
    const rawPriorizations = await readAll({
      _collection: "priorizations",
    });
    const rawComments = await readAll({
      _collection: "comments",
    });
    const raws = {
      posts: rawPosts,
      priorizations: rawPriorizations,
      comments: rawComments,
    };
    window.localStorage.setItem("@Central", JSON.stringify(raws));
    return {
      rawPosts,
      rawPriorizations,
      rawComments,
    };
  };

  const fetchPost = useCallback(
    async (props?: IFetchPost) => {
      const rawsCache = window.localStorage.getItem("@Central");
      let rawPosts, rawPriorizations, rawComments;
      setLoading(true);
      if (props?.getCache && rawsCache) {
        const props = JSON.parse(rawsCache) || {
          posts: [],
          priorizations: [],
          comments: [],
        };
        rawPosts = props?.posts;
        rawPriorizations = props?.priorizations;
        rawComments = props?.comments;
      } else {
        const props = await fetchPostDB();
        rawPosts = props?.rawPosts;
        rawPriorizations = props?.rawPriorizations;
        rawComments = props?.rawComments;
      }

      const jsonFiltered = (rawPosts?.data || []) as IPost[];
      if (jsonFiltered?.length) {
        const _arrCitys = filters?.city;
        const wasPostByCity = jsonFiltered?.filter(
          (post) =>
            normalize(post?.address?.city) ===
            normalize(props?.cityParam || city).replace("/", "")
        );

        if (wasPostByCity?.length || _arrCitys?.length) {
          const arr = jsonFiltered
            ?.sort(
              (a, b) =>
                convertToDate(a.createdAt.date, b.createdAt.hour).getTime() -
                convertToDate(b.createdAt.date, b.createdAt.hour).getTime()
            )
            .filter((post) =>
              !filters?.category.length
                ? post
                : filters?.category
                    .map((_cIndex) => categoryIndex[_cIndex])
                    .includes(post?.type)
            )
            .filter((post) =>
              _arrCitys?.length
                ? _arrCitys.includes(post.address.city)
                : normalize(post?.address?.city) ===
                  normalize(city).replace("/", "")
            )
            .filter((post) => {
              if (filters?.status === 2)
                return (
                  post?.status?.toLowerCase()?.replace(" ", "") === "emaberto"
                );
              if (filters?.status === 3)
                return (
                  post?.status?.toLowerCase()?.replace(" ", "") === "resolvido"
                );
              return post;
            })
            .filter((post) => {
              const yearPost = post?.createdAt?.date?.slice(-4);
              const monthPost = post?.createdAt?.date?.slice(3, 5);
              const dayPost = post?.createdAt?.date?.slice(0, 2);
              const yearCurrent = new Date().getFullYear().toString();
              const monthCurrent = (new Date().getMonth() + 1)
                .toString()
                .padStart(2, "0");
              const dayCurrent = new Date()
                .getDate()
                .toString()
                .padStart(2, "0");
              if (filters?.data === 2) return yearPost === yearCurrent;
              if (filters?.data === 3) return monthPost === monthCurrent;
              if (filters?.data === 4) return dayPost === dayCurrent;
              return post;
            });

          const prioritizationsByPost: any = {};
          const commentsByPost: any = {};
          const userIdsByPost: any = {};

          rawPriorizations?.data?.forEach(
            (priorization: { idPost: string; idUser: string }) => {
              const postId = priorization.idPost;
              const userId = priorization.idUser;

              prioritizationsByPost[postId] =
                (prioritizationsByPost[postId] || 0) + 1;

              if (!userIdsByPost[postId]) {
                userIdsByPost[postId] = [];
              }
              userIdsByPost[postId].push(userId);
            }
          );

          rawComments?.data?.forEach((_c: IComment) => {
            const postId = _c?.postId;
            commentsByPost[postId] = (commentsByPost[postId] || 0) + 1;
          });

          rawPosts?.data?.forEach((post: IPost) => {
            const postId = post.id || "";
            post.reactions.comments = commentsByPost[postId] || 0;
            post.reactions.prioritizations = prioritizationsByPost[postId] || 0;
            post.reactions.userIdPrioritizations = userIdsByPost[postId] || [];
          });

          if (jsonFiltered?.length)
            setPosts(
              arr.sort(
                (a, b) =>
                  b?.reactions?.prioritizations - a?.reactions?.prioritizations
              )
            );
          setPriorizations(rawPriorizations?.data);
          setComments(rawComments?.data);
          setLoading(false);
        }
      }
      setLoading(false);
    },
    [filters, address]
  );

  const fetchComments = async (postId: string) => {
    setLoading(true);
    const rawComments = await findDocs({
      _collection: "comments",
      _condition: {
        field: "postId",
        operator: "==",
        value: postId,
      },
    });
    const comments = rawComments?.data as IComment[];
    setLoading(false);
    setCommentsByPost(
      comments?.sort((a, b) => {
        const convertDate = (dateString: string) => {
          const [day, month, year] = dateString.split("/");
          return `${year}-${month}-${day}`;
        };
        const convertHour = (hourString: string) => {
          return hourString + ":00";
        };
        const dateA = new Date(
          convertDate(a.createdAt.date) + "T" + convertHour(a.createdAt.hour)
        );
        const dateB = new Date(
          convertDate(b.createdAt.date) + "T" + convertHour(b.createdAt.hour)
        );
        return dateB.getTime() - dateA.getTime();
      })
    );
  };

  const prioritizePost = async (props: {
    _wasPrioritizedByIdUser: boolean;
    _idPost: string;
  }) => {
    const postId = props?._idPost;
    const postStatus = posts?.find((_p) => _p.id === postId)?.status || "";
    if (postStatus?.toLowerCase().replace(" ", "") === "resolvido") {
      toast({
        status: "info",
        isClosable: true,
        description: "Esse chamado já foi resolvido !",
      });
      return;
    }
    if (props?._wasPrioritizedByIdUser) {
      toast({
        description: "Você já priorizou esse chamado.",
        status: "info",
        isClosable: true,
      });
      return;
    }
    const res = await create({
      _collection: "priorizations",
      _data: {
        idPost: props?._idPost,
        idUser: user?.id,
        createdAt: new Date(),
      },
    });
    if (res?.msg === "s") {
      fetchPost();
      toast({
        description: "Você priorizou esse chamado.",
        status: "success",
        isClosable: true,
      });
    }
  };

  const handleResolvePost = async (props: IResolvePost) => {
    const status =
      props?.post?.status?.toLowerCase().replace(" ", "") === "emaberto"
        ? "RESOLVIDO"
        : "Em aberto";
    const res = await update({
      _collection: "posts",
      _docId: props?.post?.id || "",
      _data: { status },
    });
    if (res?.msg === "e") {
      toast({
        status: "error",
        isClosable: true,
        description: `Problema ao ${
          props?.post?.status?.toLowerCase().replace(" ", "") === "emaberto"
            ? "resolver"
            : "reabrir"
        } chamado`,
      });
      return;
    }
    fetchPost();
    toast({
      status: "success",
      isClosable: true,
      description: `Chamado foi ${
        props?.post?.status?.toLowerCase().replace(" ", "") === "emaberto"
          ? "resolvido"
          : "reaberto"
      } com sucesso.`,
    });
  };

  onAuthStateChanged(auth, (_user) => {
    user = {
      id: _user?.uid || "",
      name: _user?.displayName || "",
    };
  });

  useEffect(() => {
    async function fetchAdd() {
      await requestLocationPermission(setAddress);
    }
    fetchPost();
    fetchAdd();
  }, []);

  useEffect(() => {
    fetchPost();
  }, [address]);

  const state: IContext = {
    address,
    filters,
    modal,
    loading,
    posts,
    priorizations,
    comments,
    commentsByPost,
    user,
    showFilters,
    setShowFilters,
    fetchPost,
    fetchComments,
    onOpen,
    onClose,
    handleRemove,
    handleSubmitNewPost,
    handleSubmitNewComment,
    handleRegister,
    handleLogin,
    setFilters,
    normalize,
    prioritizePost,
    itemId,
    onOpenRemoveDialog,
    handleResolvePost,
    setCommentsByPost,
  };
  return <AllContext.Provider value={state}>{children}</AllContext.Provider>;
}

export const useAllContext = () => {
  const context = useContext(AllContext);
  return {
    ...context,
  };
};
