/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react";
import LayoutApp from "../../layout";
import { Card, Chip, Divider } from "@mui/material";
import { ArrowLeft, Megaphone, MessageCircleMore, Trash } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import XCardPost from "../../components/x-card-post";
import XButton from "../../components/x-button";
import { IPost } from "../home";
import { IComment, useAllContext } from "../../context";
import XDialogRemoveItem from "../../components/x-dialog-remove-item";
import { useToast } from "@chakra-ui/react";
import XDialogItemResolved from "../../components/x-xdialog-item-resolved";
import NewPost from "../new-post";

export default function DetailPost() {
  const {
    user,
    loading,
    itemId,
    modal,
    posts,
    prioritizePost,
    fetchPost,
    handleRemove,
    onOpenRemoveDialog,
    onClose,
    onOpen,
    handleResolvePost,
    handleSubmitNewComment,
    commentsByPost: comments,
    setCommentsByPost,
    fetchComments,
  } = useAllContext();
  const nav = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const postId = id || "";
  const props = posts?.find((_p) => _p.id === postId) || ({} as IPost);
  const userId = props?.userId;
  const [focus, setFocus] = useState("");
  const [_load, setLoad] = useState(false);

  const wasPrioritizedByIdUser =
    props?.reactions?.userIdPrioritizations?.includes(user?.id);

  return (
    <LayoutApp
      controllModal={{
        onClose,
        state: modal.alert || modal.comment,
        title: modal.comment ? "Novo Comentário" : "Confirmar",
        children: modal.comment ? (
          <NewPost
            typeDefault={props?.type}
            isComment
            onSubmit={(_JSON) => {
              handleSubmitNewComment({
                postId,
                ..._JSON,
              });
              fetchComments(postId);
              onClose();
            }}
          />
        ) : (
          <XDialogRemoveItem
            title={focus === "posts" ? "chamado" : "comentário"}
            onClick={async () => {
              if (
                await handleRemove({
                  _collection: focus,
                  _docId: itemId,
                  _data: props,
                })
              ) {
                if (focus === "posts") nav(-1);
                fetchPost();
                fetchComments(postId);
                onClose();
                return toast({
                  description: `${
                    focus === "posts" ? "Chamado" : "Comentário"
                  } excluido com sucesso`,
                  status: "success",
                  isClosable: true,
                });
              }
            }}
            loading={loading}
            onClose={onClose}
          />
        ),
      }}
    >
      <div className="flex flex-col w-full  pb-5 gap-4 bg-slate-50">
        <div className="flex flex-row gap-2 items-center justify-start text-lg">
          <ArrowLeft
            onClick={() => {
              setCommentsByPost([]);
              nav(-1);
            }}
            size={30}
          />
          <span>{props?.type}</span>
        </div>
        <XCardPost
          {...props}
          seeMore={props?.id === postId}
          variant="outlined"
          wasPrioritized={wasPrioritizedByIdUser}
          canRemove={userId === user?.id}
          onRemove={() => {
            setFocus("posts");
            onOpenRemoveDialog(props?.id || "");
          }}
          onClick={function (): void {}}
          handleComment={() => {
            if (!user?.id) return nav("/auth");
            onOpen("comment");
          }}
          handlePrioritize={() => {
            if (!user?.id) return nav("/auth");
            prioritizePost({
              _idPost: props?.id || "",
              _wasPrioritizedByIdUser: wasPrioritizedByIdUser as boolean,
            });
          }}
        />

        {userId === user?.id && (
          <XDialogItemResolved
            variant={
              props?.status.toLowerCase().replace(" ", "") === "emaberto"
                ? "contained"
                : "outlined"
            }
            loading={_load}
            title={
              props?.status.toLowerCase().replace(" ", "") === "emaberto"
                ? "Esse chamado foi resolvido ?"
                : "Reabrir Chamado"
            }
            subtitle={
              props?.status.toLowerCase().replace(" ", "") === "emaberto"
                ? `Clique em 'Sim' para confirmar a resolução`
                : `Se deseja reabrir esse chamado, toque em "Sim"`
            }
            onClick={async () => {
              setLoad(true);
              await handleResolvePost({
                post: props,
              });
              setLoad(false);
            }}
          />
        )}
        {comments?.length === 0 && (
          <XDialogItemResolved
            variant={"contained"}
            loading={_load}
            title={"Faça o primeiro comentário"}
            subtitle={"Seja o primeiro a falar algo sobre o chamado "}
            onClick={() => {
              if (!user?.id) return nav("/auth");
              onOpen("comment");
            }}
            labelButton="Comentar"
          />
        )}
      </div>
      {comments?.length ? (
        <div className="flex flex-col gap-3 bg-white py-2">
          <div className="flex flex-row items-center justify-end text-sm">
            <span className="pr-2">
              {comments?.length}{" "}
              {comments?.length === 1 ? "comentário" : "comentários"}
            </span>
          </div>
          {comments?.map((i, index) => (
            <XCardPost
              seeMore={i?.postId === postId}
              key={index}
              {...i}
              isComment
              canRemove={i?.userId === user?.id}
              onRemove={() => {
                setFocus("comments");
                onOpenRemoveDialog(i?.id || "");
              }}
            />
          ))}
        </div>
      ) : null}
    </LayoutApp>
  );
}
