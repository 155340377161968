import { ButtonProps, CircularProgress } from "@mui/material";
import React from "react";

export default function XButton({
  onClick,
  loading,
  variant,
  name,
}: ButtonProps & { loading?: boolean }) {
  const base = `items-center justify-center w-full h-[50px] font-bold rounded-md `;
  const vSolid = `text-white bg-red-500 active:bg-red-300`;
  const vOutline = `text-black bg-white active:bg-slate-200`;

  return (
    <button
      onClick={onClick}
      className={`${base} ${loading && "bg-transparent"} ${
        variant === "outlined" ? vOutline : vSolid
      } `}
    >
      {loading ? <CircularProgress size={20} /> : <span>{name}</span>}
    </button>
  );
}
