import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import XButton from "../../../components/x-button";
import {
  CPFIsValid,
  ageIsValid,
  formatCPF,
  formatDateBorn,
} from "../../../utils";
import { useToast } from "@chakra-ui/react";

export interface IDataRegister {
  email: string;
  password: string;
  name: string;
  cpf: string;
  bornDate: string;
  address?: string;
}

export default function Register(props: any) {
  const [born, setBorn] = React.useState("");
  const [CPF, setCPF] = React.useState("");
  const toast = useToast();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const _propsValidation = {
      cpf: CPFIsValid(data.get("cpf") as string),
      bornDate: ageIsValid(data.get("bornDate") as string),
    };
    // !_propsValidation.cpf ||
    if (!_propsValidation.bornDate)
      return toast({
        status: "error",
        isClosable: true,
        description: "Idade Inválida",
        //  !_propsValidation.bornDate
        //   ? "Idade Inválida"
        //   : "CPF Inválido",
      });
    props?.onSubmit({
      email: data.get("email"),
      password: data.get("password"),
      name: data.get("name"),
      cpf: data.get("cpf"),
      bornDate: data.get("bornDate"),
      address: data.get("address"),
    });
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
      }}
    >
      <img
        className="h-28"
        src={require("../../../assets/logo.png")}
        alt="logo"
      />
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nome Completo"
          name="name"
          autoComplete="name"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="bornDate"
          label="Data de Nascimento"
          name="bornDate"
          autoComplete="bornDate"
          value={born}
          onChange={(txt) => setBorn(formatDateBorn(txt.currentTarget.value))}
        />
        <TextField
          margin="normal"
          // required
          fullWidth
          id="cpf"
          label="CPF"
          name="cpf"
          autoComplete="cpf"
          value={CPF}
          onChange={(txt) => setCPF(formatCPF(txt.currentTarget.value))}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <div className="h-4" />
        <XButton loading={props?.loading} name="Criar Conta" />
        <div className="h-2" />
      </Box>
    </Box>
  );
}
