import { ButtonProps } from "@mui/material";
import React, { ReactNode } from "react";

export default function XTagButton({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick?: () => void;
}) {
  return (
    <button
      onClick={(_ev) => {
        onClick?.();
        _ev.stopPropagation();
      }}
      className="flex flex-row  items-center justify-center gap-2  bg-gray-100 active:bg-gray-300 px-4 py-2 rounded-full"
    >
      {children}
    </button>
  );
}
