import { ReactNode } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import {
  createMuiTheme,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";
import XAppBar from "../components/x-app-bar";
import XModal, { IModal } from "../components/x-modal";

const theme = createTheme({
  palette: {
    background: {
      default: "#f8fafc", // Cor de fundo padrão
    },
  },
});
const text = "Ainda estamos trabalhando na versão desktop web";
export default function LayoutApp({
  children,
  hiddenAppBar,
  controllModal,
  hiddenButton,
}: {
  hiddenButton?: boolean;
  hiddenAppBar?: boolean;
  children: ReactNode;
  controllModal?: IModal;
}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!hiddenAppBar && <XAppBar hiddenButton={hiddenButton} />}
      {window?.innerWidth > 860 ? (
        <div className="mt-[5%] flex flex-col items-center justify-center text-4xl font-bold">
          <img alt="work" src={require("../assets/worker.png")} />
          <span className="mt-2 text-gray-500">{text.toUpperCase()} </span>
        </div>
      ) : (
        <Container
          sx={{
            py: 2,
          }}
          component="main"
          maxWidth="lg"
        >
          <XModal {...controllModal} />
          <body>{children}</body>
        </Container>
      )}
    </ThemeProvider>
  );
}
