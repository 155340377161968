/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-undef */
import LayoutApp from "../../layout";
import { Paper, IconButton, InputBase } from "@mui/material";
import { ChevronDown, SearchIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAllContext } from "../../context";
import { useState } from "react";
import XTagButton from "../../components/x-tag-button";
import { useToast } from "@chakra-ui/react";

export default function LandingPage() {
  const nav = useNavigate();
  const { normalize, fetchPost } = useAllContext();
  const [city, setCity] = useState("");
  const toast = useToast();

  function handleSearchCity() {
    if (!city) {
      return toast({
        description: "Insira o nome da sua cidade !",
        isClosable: true,
        status: "error",
      });
    }
    fetchPost({
      cityParam: normalize(city),
    });
    nav(`/${normalize(city)}`);
    location?.reload();
  }
  return (
    <LayoutApp hiddenButton>
      <div className="relative flex flex-col w-full h-full items-center justify-center text-center font-bold gap-1 bg-white py-10 px-4">
        <span className="text-3xl text-zinc-800">
          Conectando cidadãos para uma
        </span>
        <span className="text-5xl font-extrabold text-zinc-800">
          Cidade Melhor
        </span>
        <Paper
          onSubmit={handleSearchCity}
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            margin: "20px",
          }}
        >
          <InputBase
            onChange={(ev) => setCity(ev.currentTarget.value)}
            value={city}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Procure por sua cidade..."
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton
            onClick={handleSearchCity}
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <span className="text-lg px-2 text-gray-500 font-normal">
          Relate problemas anonimamente, monitore chamados, interaja com outros
          usuários e priorize as questões mais importantes da cidade.
        </span>
        <div className="h-48"></div>
        <XTagButton onClick={() => nav("/saiba-mais")}>
          <span className="">Saiba mais</span>
          <ChevronDown className="text-center self-center" />
        </XTagButton>
      </div>
    </LayoutApp>
  );
}
