import { Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import XButton from "../../components/x-button";
import { IPost } from "../home";
import { IAddressResponse, cats, useAllContext } from "../../context";
import { useToast } from "@chakra-ui/react";

export default function NewPost({
  onSubmit,
  isComment,
  typeDefault,
}: {
  onSubmit: (data: IPost) => void;
  isComment?: boolean;
  typeDefault?: string;
}) {
  const [type, setType] = useState(cats[0]);
  const { address, user } = useAllContext();
  const toast = useToast();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event?.currentTarget);
    const _type = data.get("type") || "";
    const _text = data.get("text") || "";
    if (!_text) {
      return toast({
        title: "Houve um problema",
        description: "Você precisa complementar com uma breve descrição",
        status: "error",
        isClosable: true,
      });
    }
    const json: IPost = {
      userId: user?.id,
      type: (isComment ? typeDefault : _type) as string,
      text: _text as string,
      status: "Em aberto",
      createdAt: {
        date: new Date().toLocaleDateString(),
        hour: new Date().toLocaleTimeString().slice(0, 5),
      },
      reactions: { comments: 0, prioritizations: 0 },
      address: address || ({} as IAddressResponse),
      userAgent: navigator.userAgent,
    };
    onSubmit(json);
  };

  return (
    <div>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        {!isComment && (
          <>
            <span>Escolha a categoria</span>
            <Select
              className="w-full mt-1"
              size="medium"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              name="type"
              onChange={(txt) => setType(txt.target.value)}
            >
              {cats?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <div className="h-4" />
        <span>Escreva algo</span>
        <TextField
          name="text"
          className="w-full mt-1"
          placeholder={`Por exemplo: Há alguns postes danificados no bairro X, na rua X, causando apreensão na vizinhança durante a noite.`}
          multiline
          rows={5}
          maxRows={Infinity}
        />
        <div className="h-6" />
        <XButton name={isComment ? "Publicar Comentário" : "Publicar"} />
      </Box>
    </div>
  );
}
