import React from "react";
import LayoutApp from "../../layout";
import { ArrowLeft, Copy } from "lucide-react";
import XTagButton from "../../components/x-tag-button";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

export default function KnowMore() {
  const nav = useNavigate();
  const toast = useToast();
  const handleClick = () => {
    const emailSpan = document.getElementById("email-span");

    if (emailSpan) {
      const range = document.createRange();
      range.selectNode(emailSpan);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);

      try {
        document.execCommand("copy");
        console.log("Email copiado com sucesso:", emailSpan.innerText);
        toast({
          description: "apoiecidademelhor@gmail.com",
          isClosable: true,
          status: "success",
        });
      } catch (error) {
        console.error("Erro ao copiar email:", error);
      }

      window.getSelection()?.removeAllRanges();
    }
  };

  return (
    <LayoutApp hiddenButton>
      <div className="flex flex-col items-start justify-center gap-2 bg-white p-5 pr-6 text-start">
        <XTagButton onClick={() => nav("/")}>
          <ArrowLeft />
          <span> Voltar</span>
        </XTagButton>
        <div className="h-3" />
        <span className="font-bold text-xl">Olá, tudo bem ?</span>
        <span>
          Bem-vindo ao Cidade Melhor, onde cada chamado importa e cada voz é
          ouvida!
        </span>
        <span>
          Aqui, você pode reportar problemas anonimamente, gerenciar o status
          dos chamados, interagir com outros usuários e priorizar as questões
          mais urgentes da sua cidade.
        </span>
        <span className="font-bold text-xl mt-4">⚠️ O Problema </span>
        <div className="flex flex-col">
          <span className="font-bold">• Comunicação de Problemas Urbanos</span>
          <span>
            Em muitas cidades, a comunicação entre os cidadãos e as autoridades
            responsáveis pelas melhorias na infraestrutura urbana pode ser
            complicada e ineficiente.
          </span>
          <span>
            Muitas vezes, os cidadãos encontram dificuldades para reportar
            problemas como buracos nas ruas, falta de iluminação pública ou
            sinalização inadequada.
          </span>
          <span>
            Além disso, há casos em que as pessoas podem se sentir inibidas ou
            desconfortáveis em se identificar ao reportar um problema, o que
            pode levar a uma falta de transparência e participação na resolução
            de questões importantes para a comunidade.
          </span>
        </div>
        <span className="font-bold text-xl mt-4">✅ A Solução </span>
        <div className="flex flex-col">
          <span className="font-bold">
            • Uma Comunidade Segura e Acolhedora
          </span>
          <span>
            O Cidade Melhor é uma solução que visa superar esses desafios,
            oferecendo uma plataforma online para que os cidadãos possam
            reportar problemas de forma fácil, rápida e anônima.
          </span>
          <span>
            Ao permitir o cadastro de chamados para melhorias urbanas, a
            plataforma permite que os cidadãos relatem problemas diretamente a
            comunidade, proporcionando uma comunicação mais eficiente e
            transparente.
          </span>
          <span>
            Com recursos como a possibilidade de categorizar os problemas,
            adicionar localizações e permitir interação entre os usuários, o
            Cidade Melhor facilita a identificação e resolução dos problemas
            urbanos.
          </span>
          <span>
            Além disso, a funcionalidade de priorização de chamados permite que
            os problemas mais urgentes recebam atenção especial, contribuindo
            para uma cidade mais segura e funcional para todos os seus
            habitantes.
          </span>
        </div>
        <span className="font-bold text-xl mt-4">⚙️ Funcionalidades </span>
        <div className="flex flex-col">
          <span className="font-bold">• Postagem Anônima</span>
          <span>
            Para garantir sua privacidade, todos os chamados são publicados
            anônimamente.
          </span>
        </div>
        <div className="flex flex-col ">
          <span className="font-bold">• Priorização de Chamados</span>
          <span>
            Ajude a destacar os chamados mais importantes marcando-os como
            priorizados. Os chamados priorizados receberão atenção especial das
            autoridades responsáveis.
          </span>
        </div>
        <div className="flex flex-col ">
          <span className="font-bold">• Gerenciamento de Status</span>
          <span>
            Mantenha seus chamados sempre bem atualizados, para melhorar a
            eficiencia em resoluções.
          </span>
        </div>
        <div className="flex flex-col ">
          <span className="font-bold">• Comentários e Interatividade</span>
          <span>
            Os usuários podem comentar nos chamados, compartilhar informações
            úteis e colaborar para encontrar soluções.
          </span>
        </div>
        <span className="font-bold text-xl mt-4">👤 Sobre o criador</span>
        <span>
          Olá! Eu sou Bruno Oliveira, o idealizador e desenvolvedor da Cidade
          Melhor.
        </span>
        <span>
          Criei esta plataforma com um propósito claro: estabelecer uma
          comunidade justa, segura e acolhedora, onde todos são tratados
          igualmente e suas vozes são ouvidas.
        </span>
        <span className="font-bold text-xl mt-4">🎉 Nos apoie</span>
        <span>
          Junte-se à mim nessa missão de construir um futuro mais promissor para
          as nossas cidades, e também contribua para o projeto através do Pix
        </span>
        <XTagButton onClick={handleClick}>
          <div className="flex flex-row gap-4 items-center">
            <span id="email-span">apoiecidademelhor@gmail.com</span>
            <Copy size={20} />
          </div>
        </XTagButton>
      </div>
    </LayoutApp>
  );
}
