
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDa3wx3jvHRbMoonXZU2wXjI3bz2iRSYXc",
  authDomain: "cidade-melhor-brasil.firebaseapp.com",
  projectId: "cidade-melhor-brasil",
  storageBucket: "cidade-melhor-brasil.appspot.com",
  messagingSenderId: "859092269655",
  appId: "1:859092269655:web:ef25b611831bf9bdfac112",
  measurementId: "G-2SPV7C6JP4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db }; 
