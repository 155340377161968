import { Checkbox, FormControlLabel } from "@mui/material";
import { categoryIndex, cats, useAllContext } from "../../context";
import XButton from "../../components/x-button";
import XTagButton from "../../components/x-tag-button";

export default function Filters() {
  const {
    filters,
    setFilters,
    showFilters,
    setShowFilters,
    onClose,
    fetchPost,
    posts,
  } = useAllContext();

  let citys: string[] = [];
  let states: string[] = [];
  posts?.forEach((_p) => {
    if (!citys.includes(_p.address.city)) citys.push(_p.address.city);
    if (!states.includes(_p.address.state)) states.push(_p.address.state);
  });
  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="flex flex-col w-full gap-6">
        {!showFilters?.data && !showFilters?.status && !showFilters?.city && (
          <div className="flex flex-col w-full items-start justify-center pt-2 gap-2">
            <div className="flex flex-row w-full items-center justify-between">
              <span>Categoria</span>
              <div className="flex flex-row items-center gap-2">
                <XTagButton
                  onClick={() => {
                    setFilters((prev) => ({ ...prev, category: [] }));
                  }}
                >
                  <span className="text-sm">Limpar Categorias</span>
                </XTagButton>
              </div>
            </div>
            {showFilters?.category ? (
              <div className="h-96 w-full mb-2 flex flex-col overflow-y-scroll">
                {cats?.map((i, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={(ev) =>
                          setFilters((prev) => {
                            const arr = prev.category.filter(
                              (i) => i === index + 1
                            ).length
                              ? prev.category.filter((i) => i !== index + 1)
                              : [
                                  ...prev.category.filter(
                                    (i) => i !== index + 1
                                  ),
                                  index + 1,
                                ];

                            return {
                              ...prev,
                              category: [...arr],
                            };
                          })
                        }
                        checked={filters?.category.includes(index + 1) && true}
                      />
                    }
                    label={i}
                  />
                ))}
              </div>
            ) : (
              <XTagButton
                onClick={() =>
                  setShowFilters((_prev) => ({ ..._prev, category: true }))
                }
              >
                <span className="text-lg">Escolha as categorias</span>
              </XTagButton>
            )}
            {filters?.category.length > 0 && (
              <div className="flex flex-row w-full">
                <div className="gap-2 flex flex-row items-center overflow-x-scroll h-[50px] w-full ">
                  {filters?.category?.map((_category, _index) => (
                    <XTagButton key={_index}>
                      <span className="text-sm whitespace-nowrap mr-2">
                        {categoryIndex[_category]}
                      </span>
                    </XTagButton>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {/* city */}
        {/* {!showFilters?.data &&
          !showFilters?.status &&
          !showFilters?.category &&
          posts.length > 0 && (
            <div className="flex flex-col w-full items-start justify-center pt-2 gap-2">
              <div className="flex flex-row w-full items-center justify-between">
                <label>Cidade</label>
                <div className="flex flex-row items-center gap-2">
                  <XTagButton
                    onClick={() => {
                      setFilters((prev) => ({ ...prev, city: [] }));
                    }}
                  >
                    <span className="text-xs">Limpar Cidades</span>
                  </XTagButton>
                </div>
              </div>
              {showFilters?.city ? (
                <div className="h-fit w-full mb-2 flex flex-col overflow-y-scroll">
                  {citys?.map((i, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          onChange={(ev) =>
                            setFilters((prev) => {
                              const arr = prev.city.filter(
                                (_city) => _city === i
                              ).length
                                ? prev.city.filter((_city) => _city !== i)
                                : [
                                    ...prev.city.filter((_city) => _city !== i),
                                    i,
                                  ];
                              return {
                                ...prev,
                                city: [...arr],
                              };
                            })
                          }
                          checked={filters?.city.includes(i) && true}
                        />
                      }
                      label={i}
                    />
                  ))}
                </div>
              ) : (
                <XTagButton
                  onClick={() =>
                    setShowFilters((_prev) => ({ ..._prev, city: true }))
                  }
                >
                  <span className="text-sm">Escolha as cidades</span>
                </XTagButton>
              )}
              {filters?.city.length > 0 && (
                <div className="flex flex-row w-full">
                  <div className="gap-2 flex flex-row items-center overflow-x-scroll h-[50px] w-full ">
                    {filters?.city?.map((_city, _index) => (
                      <XTagButton key={_index}>
                        <span className="text-sm whitespace-nowrap mr-2">
                          {_city}
                        </span>
                      </XTagButton>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )} */}

        {!showFilters?.data && !showFilters?.category && !showFilters?.city && (
          <div className="flex flex-col w-full items-start justify-center gap-2">
            <span>Status</span>
            {showFilters?.status ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(ev) =>
                        setFilters((prev) => ({
                          ...prev,
                          status: 1,
                        }))
                      }
                      checked={filters?.status === 1 && true}
                    />
                  }
                  label="Todos"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(ev) =>
                        setFilters((prev) => ({
                          ...prev,
                          status: 2,
                        }))
                      }
                      checked={filters?.status === 2 && true}
                    />
                  }
                  label="Em aberto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(ev) =>
                        setFilters((prev) => ({
                          ...prev,
                          status: 3,
                        }))
                      }
                      checked={filters?.status === 3 && true}
                    />
                  }
                  label="Resolvido"
                />
              </>
            ) : (
              <XTagButton
                onClick={() =>
                  setShowFilters((_prev) => ({ ..._prev, status: true }))
                }
              >
                <span className="text-sm">
                  {filters.status === 1
                    ? "Todos"
                    : filters.status === 2
                    ? "Em aberto"
                    : "Resolvido"}
                </span>
              </XTagButton>
            )}
          </div>
        )}

        {!showFilters?.category &&
          !showFilters?.status &&
          !showFilters?.city && (
            <div className="flex flex-col w-full items-start justify-center gap-2">
              <span className="text-">Data</span>
              {showFilters?.data ? (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(ev) =>
                          setFilters((prev) => ({
                            ...prev,
                            data: 1,
                          }))
                        }
                        checked={filters?.data === 1 && true}
                      />
                    }
                    label="Período Todo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(ev) =>
                          setFilters((prev) => ({
                            ...prev,
                            data: 2,
                          }))
                        }
                        checked={filters?.data === 2 && true}
                      />
                    }
                    label="Ano"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(ev) =>
                          setFilters((prev) => ({
                            ...prev,
                            data: 3,
                          }))
                        }
                        checked={filters?.data === 3 && true}
                      />
                    }
                    label="Mês"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(ev) =>
                          setFilters((prev) => ({
                            ...prev,
                            data: 4,
                          }))
                        }
                        checked={filters?.data === 4 && true}
                      />
                    }
                    label="Dia"
                  />
                </>
              ) : (
                <XTagButton
                  onClick={() =>
                    setShowFilters((_prev) => ({ ..._prev, data: true }))
                  }
                >
                  <span className="text-sm">
                    {filters.data === 1
                      ? "Período todo"
                      : filters.data === 2
                      ? "Ano"
                      : filters.data === 3
                      ? "Mês"
                      : "Dia"}
                  </span>
                </XTagButton>
              )}
            </div>
          )}
      </div>

      <XButton
        name={
          showFilters?.category ||
          showFilters?.data ||
          showFilters?.status ||
          showFilters?.city
            ? "Confirmar"
            : "Aplicar Filtros"
        }
        onClick={() => {
          setShowFilters({
            category: false,
            data: false,
            status: false,
            city: false,
          });
          if (
            showFilters?.category ||
            showFilters?.data ||
            showFilters?.status ||
            showFilters?.city
          )
            return;
          fetchPost({
            getCache: true,
          });
          onClose();
        }}
      />
    </div>
  );
}
