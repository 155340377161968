import { useState } from "react";
import Login, { IDataLogin } from "./login";
import { Divider } from "@mui/material";
import LayoutApp from "../../layout";
import XButton from "../../components/x-button";
import Register, { IDataRegister } from "./register";
import { useAllContext } from "../../context";
import { useNavigate } from "react-router-dom";

export default function AuthScreen() {
  const [indexPage, setIndexPage] = useState(0);
  const { handleRegister, handleLogin, loading, address, normalize } =
    useAllContext();
  const nav = useNavigate();

  return (
    <LayoutApp hiddenAppBar>
      <div className="flex flex-col bg-white px-4">
        {!indexPage ? (
          <Login
            onSubmit={async (_data: IDataLogin) => {
              if (await handleLogin(_data)) {
                const city = normalize(address?.city);
                address?.city ? nav(`/${city}`) : nav(`/`);
              }
            }}
            loading={loading}
          />
        ) : (
          <Register
            onSubmit={async (_data: IDataRegister) => {
              if (await handleRegister(_data)) {
                const city = normalize(address?.city);
                address?.city ? nav(`/${city}`) : nav(`/`);
              }
            }}
            loading={loading}
          />
        )}
        <div className="h-2" />
        {!loading && (
          <>
            <Divider />
            <div className="h-4" />
            <XButton
              variant="outlined"
              name={!indexPage ? "Não tenho uma conta" : "Já tenho uma conta"}
              onClick={() => setIndexPage((prev) => (!prev ? 1 : 0))}
            />
          </>
        )}
        <div className="h-10" />
      </div>
    </LayoutApp>
  );
}
