import { Card, Chip, Divider } from "@mui/material";
import { Trash } from "lucide-react";
import { IPost } from "../../modules/home";
import { Campaign, CampaignOutlined, ChatOutlined } from "@mui/icons-material";
import { ReactNode, useState } from "react";

export default function XCardPost({
  type,
  status,
  createdAt,
  text,
  reactions,
  isComment,
  wasPrioritized,
  canRemove,
  variant,
  onClick,
  onRemove,
  handleComment,
  handlePrioritize,
  isCard = true,
  seeMore,
}: IPost & {
  onRemove?: () => void;
  onClick?: () => void;
  handleComment?: () => void;
  handlePrioritize?: () => void;
  isComment?: boolean;
  wasPrioritized?: boolean;
  canRemove?: boolean;
  variant?: "outlined" | "elevation";
  isCard?: boolean;
  seeMore?: boolean;
}) {
  return (
    <Father isCard={isCard} variant={variant || "outlined"}>
      <div
        onClick={onClick}
        className="flex flex-col relative text-sm pt-4 py-2 px-4 bg-white"
      >
        {canRemove && (
          <button
            className="absolute right-3 top-2 flex flex-col items-center justify-center  bg-gray-100 active:bg-gray-200 p-2 rounded-full"
            onClick={(_ev) => {
              onRemove?.();
              _ev.stopPropagation();
            }}
          >
            <Trash className="" size={19} color="red" />
          </button>
        )}
        <div className="items-center justify-start flex flex-row gap-4">
          <span className="font-bold text-lg">{type}</span>
          {!isComment && (
            <button
              onClick={(_ev) => {}}
              className="flex flex-row items-center justify-center gap-2  bg-gray-100 active:bg-gray-300 px-3 py-1 text-xs rounded-full"
            >
              {status}
            </button>
          )}
        </div>

        <span className="text-xs text-gray-500">
          {createdAt?.date} - {createdAt?.hour}
        </span>
        <div className="h-3" />
        <Divider />
        <div className="h-2" />

        <span className="mt-3 text-base">
          {text?.length > 140 && !seeMore ? `${text?.slice(0, 140)}...` : text}
        </span>

        {!isComment && (
          <div className="mt-4 flex justify-end gap-2 items-center">
            <button
              onClick={(_ev) => {
                handleComment?.();
                _ev.stopPropagation();
              }}
              className="flex flex-row items-center justify-center gap-2  bg-gray-100 active:bg-gray-300 px-4 py-2 rounded-full"
            >
              <ChatOutlined fontSize="small" />
              <span>{reactions?.comments}</span>
            </button>
            <button
              onClick={(_ev) => {
                handlePrioritize?.();
                _ev.stopPropagation();
              }}
              className="flex flex-row items-center justify-center gap-2  bg-gray-100 active:bg-gray-300 px-4 py-2 rounded-full"
            >
              {wasPrioritized ? (
                <Campaign className="text-red-500" />
              ) : (
                <CampaignOutlined />
              )}
              <span className="text-sm">
                {`${reactions?.prioritizations} ${
                  reactions?.prioritizations > 0
                    ? `${
                        reactions?.prioritizations === 1
                          ? "Priorização"
                          : "Priorizações"
                      } `
                    : ""
                }`}
              </span>
            </button>
          </div>
        )}
      </div>
    </Father>
  );
}

function Father(props: {
  isCard: boolean;
  variant?: "outlined" | "elevation";
  children: ReactNode;
}) {
  if (props?.variant === "outlined")
    return <div className="shadow">{props?.children}</div>;
  if (props?.isCard)
    return (
      <Card variant={props?.variant || "outlined"}>{props?.children}</Card>
    );
  return <>{props?.children}</>;
}
