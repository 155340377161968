import axios from "axios";
import { IAddressResponse } from "../../context";
import { Dispatch } from "react";

interface IGeolocation {
  address: {
    road: string;
    city: string;
  };
}

const api = axios.create({
  baseURL: "https://my-json-server.typicode.com/andrecampll/api-project",
  headers: {
    Authorization: "*",
  },
});

export default function useGeolocation() {
  async function requestLocationPermission(
    set?: Dispatch<IAddressResponse | undefined>
  ): Promise<IAddressResponse | undefined> {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise<GeolocationPosition>(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          }
        );
        const { latitude, longitude } = position.coords;
        const res = await getAddress([latitude, longitude]);
        set?.(res);
        return res;
      } catch (error: any) {
        if (error.code === error.PERMISSION_DENIED) {
          console.error("User denied the request for Geolocation.");
        } else {
          console.error("Geolocation error:", error.message);
        }
        return undefined;
      }
    } else {
      console.error("Geolocation is not supported");
      return undefined;
    }
  }

  const getAddress = async (
    location: [latitude: number, longitude: number]
  ) => {
    try {
      const res = await api.get<IGeolocation>(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location[0]}&lon=${location[1]}&zoom=18&addressdetails=1`
      );
      const address = res?.data?.address as unknown as IAddressResponse;
      return address;
    } catch (error) {
      return undefined;
    }
  };

  return { requestLocationPermission };
}
